
import { ApiBase } from "@/core/api";
import {
  defineComponent,
  ref,
  onBeforeMount,
  onMounted,
  onUpdated,
  nextTick,
  getCurrentInstance,
} from "vue";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { TaggingItem } from "@/core/directive/interface/common";
import WindowResize from "@/components/layout/WindowResize.vue";
import { removeReadonly } from "@/core/directive/function/common";

interface Filter {
  customer_id: string;
  config_id: string;
  created_at_start: string;
  created_at_end: string;
}

export default defineComponent({
  components: { WindowResize },
  name: "filter-sales-invoice-dropdown",
  props: {
    type: {
      type: Number,
      default: 0,
    },
  },
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const currentInstance: any = getCurrentInstance();

    const draftOrderRef = ref<null | HTMLElement>();
    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref<Filter>({
      customer_id: "",
      config_id: "",
      created_at_start: "",
      created_at_end: "",
    });

    const options = ref({
      customer: [] as TaggingItem[],
      customerLoading: false,
      config_id: [] as TaggingItem[],
    });

    const cancelReadonly = (async) => {
      removeReadonly(async, currentInstance);
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["draft_order_store_type"],
      });
      if (data.code == 0) {
        options.value.config_id = data.data.draft_order_store_type.items;
      }
    };

    const getCustomerOptions = async (query: string) => {
      options.value.customerLoading = true;
      const { data } = await ApiBase.getCustomerSourceData({ name: query });
      options.value.customerLoading = false;
      if (data.code == 0) {
        options.value.customer = data.data;
      }
    };

    const debounceCustomerSearch = _.debounce(getCustomerOptions, 1000);

    const searchCustomerItems = (query: string) => {
      debounceCustomerSearch(query);
    };

    const getFromInfo = () => {
      // loading.value = true;
      Promise.all([getTaggingData()])
        .then(() => {
          // loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      cancelReadonly(false);
    });

    onBeforeMount(() => {
      getFromInfo();
    });

    return {
      t,
      formData,
      formRef,
      submit,
      handleReset,
      options,
      cancelReadonly,
      searchCustomerItems,
    };
  },
});
