
import {
  defineComponent,
  ref,
  onMounted,
  getCurrentInstance,
  computed,
} from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { ApiBase, ApiDraftOrders } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import JwtService from "@/core/services/JwtService";
import { ElNotification, ElUpload } from "element-plus";
import ElSelect from "element-plus/es/components/select";
import {
  commonChangeDefaultDate,
  modalShowListener,
  removeReadonly,
} from "@/core/directive/function/common";
import router from "@/router";
import { TaggingItem } from "@/core/directive/interface/common";
import { getDraftOrderRouteUrl } from "@/core/directive/function/doubleSaleOrder";
import { useRoute } from "vue-router";

interface File {
  id: number;
  size: number;
  url: string;
}

export default defineComponent({
  name: "add-sales-orders-modal",
  props: {
    type: {
      type: Number,
      default: 0, // 0: sales order draft orders 1: double sale order draft orders
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const currentInstance: any = getCurrentInstance();

    const formRef = ref<null | HTMLFormElement>(null);
    const addDraftOrderModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);

    const selectCustomer = ref<InstanceType<typeof ElSelect>>();

    const uploadRef = ref<InstanceType<typeof ElUpload>>();
    const fileList = ref<Array<File>>([]);
    const uploadList = ref<Array<any>>([]);
    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });
    const uploadLoading = ref<boolean>(false);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      config_id: "",
      customer: {},
      customer_id: "",
      customer_name: "",
      grand_total_base: 0.0,
      base_currency_code: "EUR",
      deposit_payment_status: "",
      deposit_amount: 0.0,
      deposit_currency: "CNY",
      tagging: "",
      distribution_model: "",
      payment_method: "",
      due_date: "",
      comment: "",
      file_id: [] as number[],
    });

    const options = ref({
      currency: [
        {
          label: "EUR",
          value: "EUR",
        },
        {
          label: "CNY",
          value: "CNY",
        },
      ],
      customer: [] as TaggingItem[],
      customerLoading: false,
      config_id: [] as TaggingItem[],
      distribution_model: [] as TaggingItem[],
      order_status: [
        {
          label: t("salesOrder.orderStatusNew"),
          value: "1",
        },
        {
          label: t("salesOrder.orderStatusProcessed"),
          value: "2",
        },
        {
          label: t("salesOrder.orderStatusVoid"),
          value: "3",
        },
      ],
      deposit_payment_status: [
        {
          label: t("salesOrder.paymentStatusPending"),
          value: "1",
        },
        {
          label: t("salesOrder.paymentStatusPaid"),
          value: "2",
        },
      ],
      draft_order_payment_methods: [] as TaggingItem[],
      tagging: [] as TaggingItem[],
    });

    const rules = ref({
      config_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      customer_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      deposit_payment_status: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      grand_total_base: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      deposit_amount: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      tagging: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      distribution_model: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      draft_order_payment_methods: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      file_id: [
        {
          required: true,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (value.length === 0) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    });

    const uploadWarningNumber = (number: number) => {
      ElNotification({
        title: t("salesOrderOverview.warning"),
        message: t("inspection.uploadImageTips", [number]),
        type: "warning",
        customClass: "warning",
        duration: 0,
      });
    };

    const getFileUrl = computed(() => {
      return fileList.value.map((item) => {
        return item.url;
      });
    });

    const onUploadChange = async (file, files) => {
      uploadList.value.push(file);
      const params = new FormData();
      let fileArr: any[] = [];
      uploadList.value.map((item) => {
        if (item.raw) {
          fileArr.push(item);
          params.append("file[]", item.raw, item.raw.name);
        }
      });
      debounceUpload(params, fileArr);
    };

    const uploadImages = async (params, files: any[]) => {
      uploadLoading.value = true;
      const { data } = await ApiDraftOrders.uploadDraftOrderFile(params);
      uploadLoading.value = false;
      if (data.code === 0) {
        let fileIds: Array<number> = [],
          arr: any[] = [],
          repeatNumber = 0;
        data.data.map((item, index) => {
          if (getFileUrl.value.indexOf(item.url) === -1) {
            // getFileUrl.value.push(item.url);
            fileIds.push(item.id);
            arr.push(item);
          } else {
            repeatNumber++;
            uploadRef.value?.handleRemove(files[index], files[index].raw);
          }
        });
        if (repeatNumber > 0) {
          uploadWarningNumber(repeatNumber);
        }
        fileList.value.push(...arr);
        formData.value.file_id.push(...fileIds);
        uploadList.value = [];
      } else {
        showServerErrorMsg(data);
        uploadList.value = [];
        files.forEach((item) => {
          uploadRef.value?.handleRemove(item, item.raw);
        });
      }
    };

    const debounceUpload = _.debounce(uploadImages, 100);

    const onUploadError = async (error, file, files) => {
      Swal.fire({
        text: t("common.uploadErrors"),
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("common.okGotIt"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    };

    const handlePreview = (file) => {
      let arr: string[] = [],
        number = 0;
      fileList.value.forEach((item, index) => {
        arr.push(item.url);
        if (item.id == file.id) {
          number = index;
        }
      });
      currentInstance.proxy.emitter.emit("preview-images", {
        sources: arr,
        sourcesIndex: number,
        sourcesSlide: number + 1,
      });
    };

    const handleRemove = (file) => {
      fileList.value.forEach((item, index) => {
        if (item.id == file.id) {
          fileList.value.splice(index, 1);
          uploadList.value.splice(index, 1);
        }
      });
      let fileIds: Array<number> = [];
      fileList.value.forEach((item) => {
        fileIds.push(item.id);
      });
      formData.value.file_id = fileIds;
    };

    const cancelReadonly = (async) => {
      removeReadonly(async, currentInstance);
    };

    const storeChange = (e) => {
      if (
        formData.value.config_id !== "1" &&
        formData.value.config_id !== "12"
      ) {
        resetUploadData();
      }
      formData.value.tagging = "";
    };

    const resetUploadData = () => {
      formData.value.file_id = [];
      fileList.value = [];
      uploadList.value = [];
    };

    const getCustomerOptions = async (query: string) => {
      options.value.customerLoading = true;
      const { data } = await ApiBase.getCustomerSourceData({ name: query });
      options.value.customerLoading = false;
      if (data.code == 0) {
        options.value.customer = data.data;
      }
    };

    const debounceCustomerSearch = _.debounce(getCustomerOptions, 1000);

    const searchCustomerItems = (query: string) => {
      debounceCustomerSearch(query);
    };

    const customerChange = (e) => {
      formData.value.customer_id = e.value;
      formData.value.customer_name = e.label;
    };

    const getTaggingData = async () => {
      if (props.type === 0) {
        const { data } = await ApiBase.getTaggingData({
          short_key: [
            "sales_order_distribution_mode",
            "draft_order_store_type",
            "draft_order_payment_methods",
            "order_tagging",
          ],
        });
        if (data.code == 0) {
          options.value.distribution_model =
            data.data.sales_order_distribution_mode.items;
          options.value.config_id = data.data.draft_order_store_type.items;
          options.value.draft_order_payment_methods =
            data.data.draft_order_payment_methods.items;
          options.value.tagging = data.data.order_tagging.items;
        }
      } else {
        const { data } = await ApiBase.getTaggingData({
          short_key: [
            "sales_order_distribution_mode",
            "draft_order_store_type",
          ],
        });
        if (data.code == 0) {
          options.value.distribution_model =
            data.data.sales_order_distribution_mode.items;
          options.value.config_id = data.data.draft_order_store_type.items;
        }
      }
    };

    const getFromInfo = () => {
      // loading.value = true;
      Promise.all([getTaggingData()])
        .then(() => {
          // loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiDraftOrders.addDraftOrder(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                emit("update-list");
                resetForm();
                hideModal(addDraftOrderModalRef.value);
                showFormSubmitSuccessMsg(() => {
                  router.push(
                    getDraftOrderRouteUrl(route) +
                      data.data.order_id +
                      "/overview"
                  );
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(addDraftOrderModalRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      formData.value = {
        config_id: "",
        customer: {},
        customer_id: "",
        customer_name: "",
        deposit_payment_status: "",
        grand_total_base: 0.0,
        base_currency_code: "EUR",
        deposit_amount: 0.0,
        deposit_currency: "CNY",
        tagging: "",
        distribution_model: "",
        payment_method: "",
        due_date: "",
        comment: "",
        file_id: [],
      };
      fileList.value = [];
      uploadList.value = [];
      emit("reset-form");
    };

    const updateList = () => {
      handleDiscard();
      emit("update-list");
    };

    const init = () => {
      if (props.type === 1) {
        // double sale draft order
        formData.value.config_id = "2";
        formData.value.distribution_model = "10";
      }
    };

    onMounted(() => {
      getFromInfo();
      modalShowListener(addDraftOrderModalRef.value, () => {
        init();
      });
      modalHideListener(addDraftOrderModalRef.value, () => {
        resetForm();
      });
      cancelReadonly(false);
    });

    return {
      t,
      commonChangeDefaultDate,
      props,
      loading,
      uploadLoading,
      uploadRef,
      uploadHeaders,
      fileList,
      formData,
      rules,
      formRef,
      addDraftOrderModalRef,
      submitButton,
      options,
      storeChange,
      onUploadChange,
      onUploadError,
      handlePreview,
      handleRemove,
      submit,
      handleDiscard,
      resetForm,
      updateList,
      searchCustomerItems,
      customerChange,
      selectCustomer,
      cancelReadonly,
    };
  },
});
